.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
      height: 40px;
      margin: 5px 25px;
      img{
        max-height: 100%;
        max-width: 100%;
      }
      transition: margin .3s;
  }
  .logo-small{
    margin: 5px 5px;
  }

  .header-layout{
    background: #fff;
    padding: 0;
    display: flex;
    justify-content: space-between;
    .about-user{
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
    }
  }
  .mb{
    display: none;
  }

  @media screen and (max-width: 767px){
    .pc{
      display: none;
    }
    .mb{
      display: block;
      .header-layout{
        width: 100%;
        height: 48px;
        z-index: 2;
        position: fixed;
        .ant-menu-inline{
          width: calc(100% + 1px);
          border-right: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }