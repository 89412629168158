@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: url("./font/Roboto-Regular.ttf");
}

.App {
  font-family: Roboto;
  color: #111;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.header {
  font-size: 20px;
  line-height: 32px;
}

.btn-excel {
  background: #1da57a;
  border: #1da57a;
  &:hover {
    background: #1da57a;
  }
  &:active {
    background: #1da57a;
  }
  &:focus {
    background: #1da57a;
  }
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-fixed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-remind-work {
  max-width: 70px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 1px;
  padding: 5px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
}
.div-image-remind-work {
  display: flex;
  justify-content: center;
}
.image-remind-work:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  cursor: pointer;
}

.div-image-remind-work .pswp__img {
  height: auto !important;
}
